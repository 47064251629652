import React from "react";
import "./Preloader.scss";
import logo from "../assets/Logo.svg"

export default function Preloader() {

  return (
    <section className="preloader__container">
      <img src={logo} alt="Requiem In Motion" className="preloader__logo"/>
      <div className="preloader__footer-wrapper">
          <h3 className="preloader__tag-line">An immersive audiovisual experience<br />presented in 360º</h3>
          <h3 className="preloader__arrow">&darr;</h3>
      </div>
    </section>
  );
}