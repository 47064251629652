import React from "react";
// import sanityClient from "../client.js";
import Fade from "react-reveal/Fade";

import "./Contact.scss";

export default function Contact() {
  //   const [postData, setPostData] = useState(null);
  //   useEffect(() => {
  //     sanityClient
  //       .fetch(
  //         `*[_type == "siteInfo"]{
  //             contact
  //             }`
  //       )
  //       .then((data) => setPostData(data))
  //       .catch(console.error);
  //   }, []);

  return (
    <section className="contact__container">
      <div className="contact__half left">
        <Fade bottom>
          <h1>get in touch:</h1>
        </Fade>
      </div>
      <div className="contact__half">
        <Fade bottom delay={400} distance={"150px"}>
          <h2>Office: +44[0] 208 245 6278</h2>
          <h2>
            <a href="mailto:office@gideonberger.studio">office@gideonberger.studio</a>
          </h2>
          <h2>
            <a href="https://gideonberger.studio/" target="_blank" rel="noreferrer">
            www.gideonberger.studio
            </a>
          </h2>
        </Fade>
      </div>

      <div className="contact__copyright">
        All content &copy; Reuquiem In Motion {new Date().getFullYear()}
      </div>
    </section>
  );
}
