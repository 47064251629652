import React from "react";
import './styles/global-styles.scss';

import Preloader from "./components/Preloader";
// import Scene from "./components/Scene";
import About from "./components/About";
import Partners from "./components/Partners";
import Team from "./components/Team";
import VimeoEmbed from "./components/Vimeo";
import Contact from "./components/Contact";


function App() {
  return (
    <div className="App">
      <Preloader />
      {/* <Scene /> */}
      <About />
      <Partners />
      <Team />
      <VimeoEmbed title={'Watch the showreel'}vimeoId={719400938}/>
      <VimeoEmbed title={'Behind The scenes'} vimeoId={731090009}/>
      <Contact />
    </div>
  );
}

export default App;
