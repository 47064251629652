import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";

import "./Partners.scss";

export default function Partners() {

  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "partners"] | order(order asc){
            name,
            partnerLogo,
            partnerLink
       }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <section className="partners__container">
      <div><h3>In partnership with:</h3></div>
      <div className="partners__wrapper">
        {postData &&
          postData.map((item, index) => (
            <div className="partners__partner-wrapper" key={index}>
                <img
                  src={urlFor(item.partnerLogo).url()}
                  alt={item.name}
                  className={`partners__logo ${index === 1 || index === 3 ? 'portrait' : ''}`}
                />
            </div>
          ))}
      </div>
    </section>

  );
}
