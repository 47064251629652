import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { PortableText } from "@portabletext/react";
import Fade from "react-reveal/Fade";

import imageUrlBuilder from "@sanity/image-url";

import "./Team.scss";

export default function Team() {
  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "team"] | order(order asc){
            name,
            role,
            company,
            profilePic,
            bio
       }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <section className="team__container">
      <Fade bottom>
        <h1>The Requiem in motion Team:</h1>
      </Fade>
      <Fade bottom delay={400} distance={'150px'}>
        <div className="team__wrapper">
          {postData &&
            postData.map((item, index) => (
              <div className="team__item-wrapper" key={index}>
                <div className="team__pic-wrapper">
                  {item.profilePic ? (
                    <img
                      src={urlFor(item.profilePic).url()}
                      alt={item.name}
                      className="team__pic"
                    />
                  ) : null}
                </div>
                <h2>{item.role}</h2>
                <h2 className="font-red">{item.name}</h2>
                {item.company ? <h3>({item.company})</h3> : null}
                <div className="team__bio">
                  <PortableText
                    value={item.bio}
                    components={PortableTextComponents}
                  />
                </div>
              </div>
            ))}
        </div>
      </Fade>
    </section>
  );
}
