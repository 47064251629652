import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Fade from "react-reveal/Fade";
import { PortableText } from "@portabletext/react";

import "./About.scss";

export default function About() {
  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "siteInfo"]{
            quote,
            about
            }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  return (
    <section className="about__container">
      {postData ? (
        <>
          <Fade bottom distance={"100px"}>
            <PortableText value={postData[0].quote} />
          </Fade>
          {/* <Fade bottom distance={"100px"}>
            <PortableText value={postData[0].about} />
          </Fade> */}
        </>
      ) : null}
    </section>
  );
}
